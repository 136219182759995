import { Icon, Stack, Text } from '@carvertical/ui';
import { Trans, useTranslation } from 'next-i18next';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/common/Tooltip';
import { useIsSubscription } from '../hooks';
import styles from './PricingExplanation.module.scss';

type PricingExplanationProps = {
  vehicleName?: string;
  licensePlateNumber?: string;
  reportCount: number;
  tooltipShown?: boolean;
};

const PricingExplanation = ({
  vehicleName,
  licensePlateNumber,
  reportCount,
  tooltipShown = true,
}: PricingExplanationProps) => {
  const { t } = useTranslation(['common', 'business']);
  const isSubscription = useIsSubscription();
  const extraCreditsCount = reportCount - 1;

  const renderExplanationLine = (text: React.ReactNode, description?: string) => (
    <Stack crossAxisAlign="center" mainAxisAlign="spaceBetween" type="horizontal" gap={2}>
      <Stack crossAxisAlign="center" type="horizontal" gap={0.5}>
        <Icon className={styles.icon} name="check-circle" size="s" />

        <Text className="leading-snug" as="span" variant="s">
          {text}
        </Text>
      </Stack>

      {tooltipShown && description && (
        <Tooltip>
          <TooltipTrigger className={styles.tooltipButton} aria-label={t('general.readMoreLabel')}>
            <Icon name="question-mark-round" size="s" />
          </TooltipTrigger>

          <TooltipContent className={styles.tooltip}>
            <Text variant="s">{description}</Text>
          </TooltipContent>
        </Tooltip>
      )}
    </Stack>
  );

  const renderExplanation = () => {
    if (isSubscription) {
      return renderExplanationLine(
        <>
          <Text as="span" inline variant="s+">
            {t('business:pricing.reportsLabel', { count: reportCount })}
          </Text>
          {` / ${t('general.monthLabel')}`}
        </>,
      );
    }

    if (!vehicleName && !licensePlateNumber) {
      return renderExplanationLine(
        <Trans
          i18nKey="pricing.pricingExplanation.creditsLabel"
          t={t}
          values={{ count: reportCount }}
          components={{ 1: <Text as="span" inline variant="s+" /> }}
        />,
        t('pricing.pricingExplanation.extraCreditsDescription'),
      );
    }

    return (
      <>
        {renderExplanationLine(
          <Trans
            i18nKey="pricing.pricingExplanation.vehicleLabel"
            t={t}
            values={{
              vehicleData: vehicleName || licensePlateNumber,
            }}
            components={{ 1: <Text as="span" inline variant="s+" /> }}
          />,
          t(`pricing.pricingExplanation.vehicleDescription${licensePlateNumber ? 'Lp' : 'Vin'}`),
        )}

        {!!extraCreditsCount &&
          renderExplanationLine(
            t('pricing.pricingExplanation.extraCreditsLabel', { count: extraCreditsCount }),
            t('pricing.pricingExplanation.extraCreditsDescription'),
          )}
      </>
    );
  };

  return (
    <Stack crossAxisAlign="stretch" gap={1.5}>
      {renderExplanation()}
    </Stack>
  );
};

export { PricingExplanation };
