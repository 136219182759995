import { Text, type DivProps } from '@carvertical/ui';
import EmptyTableIllustration from '../assets/empty-table.svg';
import EmptyListIllustration from '../assets/empty-list.svg';

type ListingEmptyStateProps = {
  title: string;
  description?: string;
} & DivProps;

const ListingEmptyState = ({ title, description, ...props }: ListingEmptyStateProps) => (
  <div {...props}>
    <div className="mb-5 hidden md:block">
      <EmptyTableIllustration className="-mb-3 w-full" />
    </div>

    <div className="-mx-3 -mt-2 block md:hidden">
      <EmptyListIllustration className="h-[333px] w-full" />
    </div>

    <Text variant="m+" className="mb-1" align="center">
      {title}
    </Text>

    {description && (
      <Text variant="s" align="center">
        {description}
      </Text>
    )}
  </div>
);

export { ListingEmptyState };
export type { ListingEmptyStateProps };
