import { Trans, useTranslation } from 'next-i18next';
import { Button, Container, Heading, Stack, Text } from '@carvertical/ui';
import { SupportEmail } from 'modules/support/components';
import { Section } from 'components/landings/common/Section';
import { CONTACT_SECTION_ID, PRESS_EMAIL } from '../constants';
import styles from './MediaContactsSection.module.scss';

const MediaContactsSection = () => {
  const { t } = useTranslation(['press', 'common']);

  return (
    <Section id={CONTACT_SECTION_ID} color="colorLightBlue100">
      <Container>
        <Stack gap={4} className={styles.content} crossAxisAlign="center">
          <Heading as="h2" variant="l" align="center">
            {t('contactUsSection.title')}
          </Heading>

          <Text variant="m" align="center">
            <Trans parent="span" i18nKey="contactUsSection.description" t={t}>
              For media inquiries, please contact us at{' '}
              <a href={`mailto:${PRESS_EMAIL}`} className={styles.link}>
                <strong>{PRESS_EMAIL}.</strong>{' '}
              </a>
              We are always happy to share more information about our studies, provide additional
              statistics from our databases, or set up a personal interview.
            </Trans>
          </Text>

          <SupportEmail defaultSubject="media">
            <Button variant="yellow" leadingIcon="mail">
              {t('common:general.contactUsAction')}
            </Button>
          </SupportEmail>
        </Stack>
      </Container>
    </Section>
  );
};

export { MediaContactsSection };
